  /* Style the navbar */
  .navbar {
    /* overflow: hidden; */
    overflow: visible;
    background-color: black;
    position: fixed;
    height: 60px;
    top: 0;
    max-width: 1080px;
    width: 100%;
    /* min-width: auto; */
    border-bottom: 1px solid #444;
    /* max-width: 2160px; */
  }

  .navbarmedium {
    /* overflow: hidden; */
    overflow: visible;
    background-color: black;
    position: fixed;
    height: 40px;
    top: 0;
    max-width: 1080px;
    width: 100%;
    /* min-width: auto; */
    border-bottom: 1px solid #444;
    /* max-width: 2160px; */
  }

  .navbarsmall {
    /* overflow: hidden; */
    overflow: visible;
    background-color: black;
    position: fixed;
    height: 30px;
    top: 0;
    max-width: 1080px;
    width: 100%;
    /* min-width: auto; */
    border-bottom: 1px solid #444;
    /* max-width: 2160px; */
  }

.image {
  display: inline-block;
  /* height: 40px; */
  width: 8.4%;
  margin-top: 12px;
  padding-left: 3rem;
  margin-left: 19px;
}

.imagemedium {
  display: inline-block;
  /* height: 40px; */
  width: 33px;
  margin-top: 6px;
  padding-left: 0px;
  margin-left: 6px;
}

.imagesmall {
  display: inline-block;
  /* height: 40px; */
  width: 24px;
  margin-top: 5px;
  padding-left: 0px;
  margin-left: 4px;
}

.imagemobile {
  display: inline-block;
  /* height: 40px; */
  width: 48px;
  margin-top: 10px;
  padding-left: 0px;
  margin-left: 6px;
}
  
  /* Navbar links */
.navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  /* .details-parent {
    padding-left: 3rem;
    padding-right: 3rem;
  } */

  .details-child-30 {
    display: inline-block;
    /* float: left; */
    width: 20%;
    margin-left: 1rem;
    margin-right: 1rem;
    vertical-align: top;
}

.details-child-80 {
  display: inline-block;
  /* float: left; */
  /* width: 50%; */
  margin-left: 1rem;
  margin-right: 0rem;
  vertical-align: top;
  overflow:visible;
}

.details-child-40 {
  display: inline-block;
  /* float: left; */
  width: 33%;
  margin-left: 1rem;
  margin-right: 1rem;
  vertical-align: top;
}

.details-child-right {
  display: inline-block;
  /* float: center; */
  width: 30%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.select {
    background-color: white;
    height: 30px;
    padding: 5px;
    margin: 16px;
}

.banner {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    vertical-align: top;
    /* padding-top: 0.5rem; */
    padding-top: 1rem;

    margin-top: 0px;
    /* padding-left: 1rem; */
    color: white;
}

.bannermobile {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  vertical-align: top;
  /* padding-top: 0.5rem; */
  padding-top: 8px;

  margin-top: 0px;
  /* padding-left: 1rem; */
  color: white;
}