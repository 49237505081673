
.menuitem {
    width:250px; /* or whatever width you want. */
    max-width:250px; /* or whatever width you want. */
    display: inline-block;
    position: relative;
    text-align: left;
    margin-left: 0;
}

a.menuitem {
    text-align: left;
}

.menuitem-green {
    background-color: #1C4A32;
}

.menuitem-green:hover {
    background-color: #688A78;
}

.menuitem-blue {
    background-color: #1C1D4A;
}

.menuitem-blue:hover {
    background-color:#68698A;
}

.menuitem-red {
    background-color: #4A1C34;
}

.menuitem-red:hover {
    background-color: #8A687A;
}


.menuitem-yellow {
    background-color: #4A491C;
}

.menuitem-yellow:hover {
    background-color: #8A8968;
}

/* .menuitem-green {
  background-color: #CDE9DB;
}

.menuitem-green:hover {
  background-color: #97ADA2;
}

.menuitem-blue {
  background-color: #CDCDE9;
}

.menuitem-blue:hover {
  background-color:#9797AD;
}

.menuitem-red {
  background-color: #E9CDDB;
}

.menuitem-red:hover {
  background-color: #AD97A2;
}


.menuitem-yellow {
  background-color: #E9E9CD;
}

.menuitem-yellow:hover {
  background-color: #ADAD97;
} */

.dropbtn {
    background-color: black;
    /* color: white; */
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
    /* margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px; */
    text-align: left;
    margin-bottom: 0px;
    /* float: left; */
  }

  .dropbtnmedium {
    background-color: black;
    /* color: white; */
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
    /* margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px; */
    text-align: left;
    padding-left: 0px;
    padding-right: 1px;
    margin-left: 0px;
    margin-right: 8px;
    margin-top: 5px;
    margin-bottom: 2px;
    padding-bottom: 5px;
    padding-top: 8px;
    /* float: left; */
  }

  .dropbtnsmall {
    background-color: black;
    /* color: white; */
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
    /* margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px; */
    text-align: left;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 1px;
    margin-left: 0px;
    margin-right: 1px;
    margin-top: 5px;
    margin-bottom: 2px;

    padding-top: 2px;
    padding-bottom: 2px;
    /* float: left; */
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    overflow: visible;
    float: right;
    margin-right: 15px;
  }

  .dropdownmedium {
    position: relative;
    display: inline-block;
    overflow: visible;
    float: right;
    margin-right: 2px;
  }

  .dropdownsmall {
    position: relative;
    display: inline-block;
    overflow: visible;
    float: right;
    margin-right: 2px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    /* float: left; */
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    /* background-color: #000000 */
}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover  .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    color: gray;
    background-color: #000000;
    border-color: black;
  }

  .dropdownmedium:hover  .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdownmedium:hover .dropbtnmedium {
    color: gray;
    background-color: #000000;
    border-color: black;
  }


  .dropdownsmall:hover  .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdownsmall:hover .dropbtnsmall {
    color: gray;
    background-color: #000000;
    border-color: black;
  }
  

  .menuicon {
    /* vertical-align: center; */
    /* background-size: cover; */
    /* width: 100px;
    height: 100px; */
    /* horizontal-align: center; */
  }
