/* Dark Style */
.table {
  color: #ddd;
  background: #333;
  font-size: 12pt;
  border-collapse: collapse;
}
.tablemobile {
  color: #ddd;
  background: #333;
  font-size: 8pt;
  border-collapse: collapse;
}
.table thead th,tfoot th {
  color: #aaa;
  background: rgba(0255,255,255,.15);
}
.table caption {
  padding:.5em;
}
.table th,td {
  padding: .5em;
  border: 1px solid grey;
}

.table tbody tr:hover {
  background: #555;
}