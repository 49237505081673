.spinnerbox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    /* width: 100%;
    height: 100%; */
    /* border: 1px dashed black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(50, 50, 50, 0.6);
  }

  .spinner {
    animation: spin infinite 1s linear;
    color: white;
    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}