.details-parent {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .details-parent-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .details-parent-mobile {
    padding-left: 0rem;
    padding-right: 0rem;
  }

.details-child-left {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: top;
    float: left;
    width: 50%;
}
.details-child-right {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: top;
  float: right;
  width: 50%;
}

.details-child {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  vertical-align: top;
  width: 100%;
}

.details-mobile {
  font-size: 8pt;
}

.details-tiny {
  font-size: 6pt;
}

.details-full {
  width: 100%;
}

.screentop {
  margin-top: 5rem;
}

.screentopmedium {
  margin-top: 3rem;
}

.screentopsmall {
  margin-top: 3rem;
}

.stock-title {
  padding-left: 4rem;
  padding-right: 4rem;
}

.stock-title-mobile {
  padding-left: 1rem;
  padding-right: 1rem;
}

.spinnerbox {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 130, 250, 0.315);
}
  