

  
  /* Page content */
  .content {
    padding: 16px;
  }
  
  /* The sticky class is added to the navbar with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    padding-top: 60px;
  }

.details-child-30 {
    display: inline-block;
    /* float: left; */
    width: 20%;
    margin-left: 1rem;
    margin-right: 1rem;
    vertical-align: top;
}

.details-child-40 {
  display: inline-block;
  /* float: left; */
  width: 40%;
  margin-left: 1rem;
  margin-right: 1rem;
  vertical-align: top;
}

.details-child-right {
  display: inline-block;
  /* float: center; */
  width: 30%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.select {
    background-color: white;
    height: 46px;
    padding: 8px;
    margin: 8px;
}

.banner {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    vertical-align: top;
    padding-top: 1rem;
    margin-top: 0px;
    padding-left: 3rem;
    color: white;
}

.screentop {
  margin-top: 5rem;
}

.centertext {
  text-align: center;
}

.panel {
  margin-left: 3rem;
  margin-right: 3rem;
}

.maintext {
  margin-left: 1rem;
  margin-right: 1rem;
}


 