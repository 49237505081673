.image {
    width:1080px;
    margin-top:16px;
}

.video {
    margin:80px;

}

.videomedium {
    margin:40px;
}

.videosmall {
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
}