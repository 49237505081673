.select {
    background-color: white;
    height: 46px;
    padding: 8px;
    margin: 8px;
    width: 100px;
}

.selectsmall {
    background-color: white;
    height: 30px;
    padding: 5px;
    margin: 5px;
    width: 50px;
}

.selecttiny {
    background-color: white;
    height: 20px;
    padding: 2px;
    margin: 2px;
    width: 40px;
}

.screentop {
    margin-top: 5rem;
}

.screentopmedium {
    margin-top: 3rem;
}

.screentopsmall {
    margin-top: 3rem;
}

.panel {
    margin-left: 1rem;
    margin-right: 1rem;
}

.label {
    margin-left: 1rem;
    /* float: left; */
    float: right;
}

.largetext {
    font-size: 16px;
    /* margin-right: 1rem; */
}

.mediumtext {
    font-size: 13px;
    /* margin-right: 10px; */
}

.smalltext {
    font-size: 11px;
    /* margin-right: 5px; */
}

.tinytext {
    font-size: 9px;
    /* margin-right: 2px; */
}

* {box-sizing:border-box;} /* global + reset */


div.text {
    /* it's display: inline by default! */
    display: grid; /* naturally 1 column and will stack */
    gap: 4px;
}

div.text label {
    /* font-size: 16px; */
    float: right;
}

div.text input {
    font: inherit;
    padding: 5px 10px;
}

div.text {
    display: grid;
    max-width: 300px; /** or some parent context to constrain it **/
}

@media (min-width: 500px) {
    form {
        max-width: 500px;
    }
    
    div.text {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 20px;
    }

    div.text label {
        white-space: nowrap; 
    }
}

@media (max-width: 499px) {
    form {
        max-width: 400px;
    }
    
    div.text {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 8px;
    }

    div.text label {
        white-space: nowrap; 
    }
}


